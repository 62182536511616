<template>
  <el-container>
    <el-header>
      <vs-button :size="windowWidth > 576 ? '' :'small'" class="ml-5" color="warning" type="filled" @click="$router.go(-1)"><< 上一頁</vs-button>
        <h3>DICOM 報告</h3>
    </el-header>
    <el-main v-loading="loading" style="height: 100%;">
        
    </el-main>
  </el-container>
</template>

<script>
import axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
export default {
  data() {
    return {
      imageurl: '',
      loading: false,
    }
  },
  components: {
    VueApexCharts,
    // StatisticsCardLine,
    // HomeVxTimeline
  },
  computed: {
      windowWidth() {
        var windowwidth = this.$store.state.windowWidth
        return windowwidth
    }
  },
  mounted() {
    var pageURL = window.location.href;
    this.selectid = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    console.log('select id ',this.selectid);

  },
  methods: {

  },
}
</script>

<style>
  
</style>